require("@rails/ujs").start()
require("turbolinks").start()
require("lightbox2")
require("lazyload")
require("src/drawer")
require("swiper")
require('jquery-tags-input/dist/jquery.tagsinput.min.js')
// import 'stylesheets/application';

// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import lightbox from 'lightbox2';
import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

$(document).on('click', '.subimage', (e) => {
  e.preventDefault();
  $(e.currentTarget.dataset.target).trigger('click');
});

$(document).on('change', '.upload_subimages input[type="file"]', (e) => {
  $('.upload_subimages h2').html('uploading...');
  e.currentTarget.form.submit();
});

$(document).on('click', '.toggle', (e) => {
  $(e.currentTarget.dataset.toggle).toggle();
});

const init = function() {
  if(typeof LineIt != "undefined") { LineIt.loadButton(); }
  if(typeof FB != "undefined") { FB.XFBML.parse(); }
  lazyload();
  lightbox.init();
  $('#tags').tagsInput({ width: '100%', height: '140px'});
  $('.alert').delay(5000).fadeOut();

  const swiper = new Swiper('.swiper-container.gallery-container', {
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  const tag_swiper =  new Swiper('.swiper-container.tag-swiper', {
    slidesPerView: 3,
    spaceBetween: 3,
    slidesPerGroup: 3,
    loop: true,
    loopFillGroupWithBlank: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
};

document.addEventListener('turbolinks:load',init, false);
document.addEventListener('turbolinks:load',
function(){
  const drawer = document.querySelector('.drawer')
  const toggleBtn = document.querySelectorAll('.drawer-trigger')

  toggleBtn.forEach(function (target) {
    target.addEventListener('click', function(){
      if(drawer.classList.contains('opened')){
        drawer.classList.remove('opened')
      } else {
        drawer.classList.add('opened')
      }
    });
  });
}, false);